import React, { useState } from "react";
import { Link } from "react-router-dom";

const WeekendModeContent = () => {
  const [isWeekendMode, setIsWeekendMode] = useState(false);

  const handleWeekendModeClick = () => {
    setIsWeekendMode((prevMode) => !prevMode);

    if (!isWeekendMode) {
      // If weekend mode is enabled
      // Add hidepage className and remove showpage className from various elements
      const elementsToHide = document.querySelectorAll(
        ".ipatco-project-detail, .ipatco-portfolio-content, .ipatco-image-page, .ipatco-soundcloud-page, .ipatco-vimeo-content, .ipatco-youtube-video-page, .ipatco-home-main-content, .weekend-mode-hide, .ipatco-slider-page"
      );
      elementsToHide.forEach((element) => {
        element.classNameList.add("hidepage");
        element.classNameList.remove("showpage");
      });
    } else {
      // If weekend mode is disabled
      // Remove showpage className from .ipatco-project-detail and .ipatco-single-blogs-content
      const elementsToShow = document.querySelectorAll(
        ".ipatco-project-detail, .ipatco-single-blogs-content"
      );
      elementsToShow.forEach((element) =>
        element.classNameList.remove("showpage")
      );

      // Remove hidepage className from .ipatco-portfolio-content, .ipatco-home-main-content, and .weekend-mode-hide
      const elementsToUnhide = document.querySelectorAll(
        ".ipatco-portfolio-content, .ipatco-home-main-content, .weekend-mode-hide"
      );
      elementsToUnhide.forEach((element) =>
        element.classNameList.remove("hidepage")
      );
    }

    // Toggle classNamees for .ipatco-weekend-mode, .ipatco-home-image, and .ipatco-weekend-image
    document
      .querySelector(".ipatco-weekend-mode")
      .classNameList.toggle("showpage");
    document
      .querySelector(".ipatco-home-image")
      .classNameList.toggle("showpage");
    document
      .querySelector(".ipatco-weekend-image")
      .classNameList.toggle("visible-img");
  };

  return (
    <>
      <div
        className={`ipatco-weekend-mode ${isWeekendMode ? "checked" : ""}`}
        id="weekend_mode"
      >
        <div className="salv-inrto-content">
          <h5 className="salv-about-title salv-home-title md-font b-text inter-font-900 mb-0">
            Me on Weekends 😎
          </h5>
        </div>
        <div className="salv-week-desc">
          <p className="mt-0 mb-0 sal-week-desc-one inter-font-400 sm-md-font-two">
            On weekends, I embark on epic adventures to the hilly regions
            because what’s a weekend without a little altitude? I’ll be driving
            up winding roads, pretending I’m in a scooty commercial, with my
            hair blowing in the wind—only to realize that my hair looks like
            I’ve just lost a fight with a tornado. The hills are always calling,
            and I must go, mostly because my GPS won’t stop rerouting me there.
          </p>
          <p className="mb-0 mt-0 sal-week-desc-two inter-font-400 sm-md-font-two">
            After conquering the hills (or getting lost a few times), I usually
            pay a visit to a friend I haven't seen in ages. We’ll start with the
            usual pleasantries, then quickly descend into an intense debate over
            who’s aged better and whose life choices are the most questionable.
            It’s always a lot of laughs, a bit of nostalgia, and maybe even a
            competition to see who can eat the most snacks in one sitting.
            Spoiler alert: I’m training for this.
          </p>
          <p className="mb-0 mt-0 sal-week-desc-two inter-font-400 sm-md-font-two">
            When I’ve had my fill of both the mountains and my friend’s endless
            stories, it’s time to unwind with a movie. But not just any
            movie—I’m talking about one of those films that’s so bad, it’s good.
            You know, the kind where you spend more time making sarcastic
            comments than actually watching the plot unfold. By the end of it,
            I’ve had more laughs than if I’d watched a comedy special, and I
            feel both entertained and slightly concerned about my taste in
            movies.
          </p>
          <p className="mb-0 mt-0 sal-week-desc-two inter-font-400 sm-md-font-two">
            Of course, no weekend is complete without a little mishap or two.
            I’m fully expecting to spill coffee on myself at least once, take a
            wrong turn and discover a “shortcut” that adds an hour to my trip,
            and maybe even get stuck behind a slow-moving tractor on a one-lane
            road. But that’s all part of the charm, right? I end up with plenty
            of stories to tell, and who knows, maybe I even learn a thing or
            two—like how to read a map.
          </p>
          <p className="mt-0 mb-0 sal-week-desc-three inter-font-400 sm-md-font-two">
            By the time Sunday evening rolls around, I’m back home, tired but
            happy, with a camera roll full of scenic photos, a belly full of
            snacks, and a heart full of memories. I usually plot my next
            adventure, but for now, I settle in with my movie, ready to laugh at
            all the ridiculousness that the weekend throws my way.
          </p>
        </div>
        <div className="salv-drop-mail">
          <div className="salv-drop-mail-wrap">
            <h6 className="mb-0 drop-mail-title inter-font-400">
              Drop Your Mail:
            </h6>
            <Link
              style={{ color: "black" }}
              to="mailto:prashant@rijal.me"
              className="salv-mail-text inter-font-900"
            >
              &nbsp;prashant@rijal.me
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default WeekendModeContent;
