import React from "react";
import WeekendModeToggle from "./home/WeekendModeToggle";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="salv-footer-content" id="slv-footer">
        <div className="salv-footer-wrap">
          <p className="salv-copyright-content mb-0 sm-font inter-font-400 b-text light-copyright">
            Copyright © {new Date().getFullYear()} by Prashant Rijal {" with "}
            <span className="text-danger">❤</span>.
          </p>
          <WeekendModeToggle />
        </div>
      </footer>
    </>
  );
};
export default Footer;
