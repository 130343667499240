import React from "react";
import clientTwoImg from "../../assets/images/testimonial/client-two.png";
import clientThreeImg from "../../assets/images/testimonial/client-three.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const TestimonialSection = () => {
  return (
    <>
      <div className="salv-about-testimonial">
        <h4 className="salv-about-personla-title mb-0 b-text sm-md-font inter-font-700">
          testimonial
        </h4>
        <div className="salv-testi-testimonial-wrap testimonial position-relative">
          <OwlCarousel
            className="sal-testimonial-carousel owl-carousel owl-theme"
            items={2}
            margin={8}
            loop
            slideSpeed={1000}
            autoplay={true}
            dots={false}
            responsive={{
              768: {
                items: 2, // Display one item on screens less than 394px wide
              },
              394: {
                items: 1, // Display two items on screens 394px wide and above
              },
              324: {
                items: 1, // Display two items on screens 394px wide and above
              },
            }}
          >
            {/* <!-- Testimonial Block --> */}
            <div className="salv-testimonial-block">
              <div className="salv-inner-box">
                <div className="salv-desc-text">
                  I have worked with Prashant on a number of projects over my
                  employment on Binastre Technologies. He is a very talented
                  developer with a keen eye for detail and always delivers on
                  time. I would highly recommend him.
                </div>
                <div className="salv-info-box">
                  <div className="salv-user-thumb">
                    <img
                      src={clientThreeImg}
                      alt="this is a testimonial image"
                    />
                  </div>
                  <div className="salv-user-details">
                    <h4 className="name">Aanchal Grover</h4>
                    <span className="designation">Developer</span>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Testimonial Block --> */}
            <div className="salv-testimonial-block">
              <div className="salv-inner-box">
                <div className="salv-desc-text">
                  I had the pleasure of working with Prashant for a year at
                  Binastre Technologies, Pearl Organisation and iPatco Tech. I
                  was impressed with his ability to handle multiple projects and
                  meet deadlines. Love to work with him.
                </div>
                <div className="salv-info-box">
                  <div className="salv-user-thumb">
                    <img src={clientTwoImg} alt="this is testimonial image" />
                  </div>
                  <div className="salv-user-details">
                    <h4 className="name">Atul Dobriyal</h4>
                    <span className="designation">Sr Developer</span>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Testimonial Block --> */}
            <div className="salv-testimonial-block">
              <div className="salv-inner-box">
                <div className="salv-desc-text">
                  首先，我不得不说我有机会与一个很棒的团队合作。
                  超级专业，对工作非常友好。
                  最好的部分是它们非常实惠，而且工作非常完美。
                  我非常喜欢在未来与这些了不起的团队一起工作。 这些小团队的 5
                  星。
                </div>
                <div className="salv-info-box">
                  <div className="salv-user-thumb">
                    <img
                      src={clientThreeImg}
                      alt="this is a testimonial image"
                    />
                  </div>
                  <div className="salv-user-details">
                    <h4 className="name">HiMe</h4>
                    <span className="designation">CEO, US</span>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
    </>
  );
};
export default TestimonialSection;
