import React, { useState } from "react";
import WeekendModeContent from "./common/WeekendModeContent";
import SocialIcon from "./common/SocialIcon";
import Footer from "./Footer";
import ZorroImg from "../assets/images/portfolio/image-one.png";
import FixerImg from "../assets/images/new/fixer.png";
import JannaImg from "../assets/images/new/janna.png";
import DefaultImg from "../assets/images/new/default.png";
import BookMeImg from "../assets/images/new/bookme.png";
import WacImg from "../assets/images/new/wac.png";
import KlokhetImg from "../assets/images/new/klokhet.png";
import EcomImg from "../assets/images/new/ecom.png";
import NtLlcImg from "../assets/images/new/ntllc.png";
import { Link } from "react-router-dom";
import ParticleAnimation from "./ParticleAnimation";
import Header from "./Header";
import Title from "./common/Title";

const Portfolio = () => {
  const [activeTab, setActiveTab] = useState("one");

  const [isBurgerClicked, setIsBurgerClicked] = useState(false);

  const [showPortfolioContent, setShowPortfolioContent] = useState(true);

  const portfolioContents = [
    {
      id: 1,
      category: "all",
      title: "Fixer",
      image: FixerImg,
      url: "https://fixer.sa/",
    },
    {
      id: 2,
      category: "all",
      title: "Janna Mart",
      image: JannaImg,
      url: "https://janna-mart.com/",
    },
    {
      id: 3,
      category: "all",
      title: "Ecole De Conduite Pointe Levy",
      image: DefaultImg,
      url: "https://ecoledeconduitepointelevy.ca/",
    },
    {
      id: 4,
      category: "all",
      title: "Book Me",
      image: BookMeImg,
      url: "https://bookme.ps/",
    },
    {
      id: 5,
      category: "all",
      title: "WaCampaign",
      image: WacImg,
      url: "https://wacampaign.com/",
    },
    {
      id: 6,
      category: "all",
      title: "Klokhet",
      image: KlokhetImg,
      url: "https://stage.klokhet.com/",
    },
    {
      id: 7,
      category: "all",
      title: "Ore",
      image: EcomImg,
      url: "https://ecom.rijal.me/",
    },
    {
      id: 8,
      category: "all",
      title: "Nepal Tea LLC",
      image: NtLlcImg,
      url: "https://nepalteallc.com/",
    },
  ];

  Title("Portfolio | Prashant Rijal");
  return (
    <>
      {/* <!--MrThito page content start here --> */}
      <div className="ipatco-page-wrap portfolio-page-wrap">
        {/* <!--MrThito portfolio content start here--> */}
        <section className="ipatco-fixed-content" id="portfolio">
          <div className="container-fluid">
            <div className="row home-content-row">
              {/* <!--Left side ipatco fixed image start here--> */}
              <ParticleAnimation />
              {/* <!--Left side ipatco fixed image end here --> */}
              {/* <!--right side ipatco content start here--> */}
              <div className="col-12 col-md-12 col-lg-8 col-xxl-7 ipatco-home-content p-0">
                <div
                  className={`ipatco-about-wrap ${
                    isBurgerClicked ? "border-none" : ""
                  }`}
                >
                  <Header active="portfolio" />
                  <div>
                    {showPortfolioContent && (
                      <div className="ipatco-about-content ipatco-portfolio-content">
                        <div className="salv-inrto-content">
                          <div className="portfolio-title">
                            <h2
                              className="salv-about-title salv-home-title md-font b-text inter-font-900 mb-0"
                              data-aos="flip-left"
                              data-aos-easing="ease-out-cubic"
                              data-aos-duration="3000"
                            >
                              Portfolio
                            </h2>
                          </div>
                          <div className="sec-text">
                            <p
                              className="salv-about-desc mb-0 second-color inter-font-400"
                              data-aos="fade-in"
                              data-aos-duration="3000"
                            >
                              Please have a glance at my portfolio. I have been
                              working on various projects. I have worked on
                              different projects like Ore, NepalTeaLLC, Klokhet,
                              JannaMart, BookMe, WaCampaign, Fixer, and many
                              more. I have worked on different stacks like
                              React, Angular, Node, Laravel, PHP, and many more.
                            </p>
                          </div>
                          <div className="salv-home-get-touch salv-portfol-get-btn">
                            <Link
                              to="/contact"
                              target="_self"
                              className="salv-get-page-btn"
                            >
                              <span className="salv-get-text">
                                if you are interested, get in touch
                              </span>
                            </Link>
                          </div>
                        </div>
                        {/* <!--portfolio tab content start here --> */}
                        <div className="salv-tab-filters">
                          <div className="salv-tab-content-box row">
                            <div
                              className={`col-lg-12 salv-tab-content content ${
                                activeTab === "one" ? "activ" : ""
                              }`}
                              id="one"
                            >
                              <div className="salv-all-content row">
                                {portfolioContents.map((content) => (
                                  <div className="col-12 col-sm-6 col-md-6 col-lg-4 portfolio-col mb-3">
                                    <div className="all-content-wrap detail-page">
                                      <div className="salv-all-image">
                                        <img
                                          src={content.image}
                                          alt="this is a image of porfolio"
                                        />
                                      </div>
                                      <div className="salv-all-conetent">
                                        <h3 className="mb-0 salv-all-title b-text inter-font-700">
                                          {content.title}
                                        </h3>
                                        <span className="salv-all-deatils sm-font mt-0">
                                          <a
                                            href={content.url}
                                            target="_blank"
                                            className="mt-0"
                                          >
                                            Visit
                                          </a>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <!--weekend-mode start here--> */}
                  <WeekendModeContent />

                  {/* <!--social icon start here --> */}
                  <SocialIcon />

                  {/* <!--footer content start here --> */}
                  <Footer />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--MrThito portfolio content end here--> */}
      </div>
    </>
  );
};
export default Portfolio;
