import React, { useState } from "react";
import PersonalInfo from "./about/PersonalInfo";
import Programming from "./about/Programming";
import ServiceSection from "./about/ServiceSection";
import EduAndExp from "./about/EduAndExp";
import Interest from "./about/Interest";
import Awards from "./about/Awards";
import ClientSection from "./about/ClientSection";
import TestimonialSection from "./about/TestimonialSection";
import Footer from "./Footer";
import WeekendModeContent from "./common/WeekendModeContent";
import SocialIcon from "./common/SocialIcon";
import ParticleAnimation from "./ParticleAnimation";
import Header from "./Header";
import Title from "./common/Title";

const About = () => {
  const [isBurgerClicked, setIsBurgerClicked] = useState(false);

  const handleBurgerClick = () => {
    setIsBurgerClicked((prevClicked) => !prevClicked);
  };
  Title("About | Prashant Rijal");
  return (
    <>
      <div className="ipatco-page-wrap">
        <section className="ipatco-fixed-content" id="about_us">
          <div className="container-fluid">
            <div className="row home-content-row">
              {/* <!--Left side ipatco fixed image start here--> */}
              <ParticleAnimation />
              {/* <!--Left side ipatco fixed image end here --> */}
              {/* <!--right side ipatco content start here--> */}
              <div className="col-12 col-md-12 col-lg-8 col-xxl-7 ipatco-home-content p-0">
                <div
                  className={`ipatco-about-wrap ${
                    isBurgerClicked ? "border-none" : ""
                  }`}
                >
                  {/* <!--header content start here --> */}
                  <Header active="about" />

                  {/* <!--MrThito About Content start --> */}
                  <div className="ipatco-about-content weekend-mode-hide">
                    <div className="salv-inrto-content">
                      <h2
                        className="salv-about-title salv-home-title md-font b-text inter-font-900 mb-0"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"
                      >
                        About
                      </h2>
                      <div className="sec-text text-anim">
                        <p
                          className="salv-about-desc mb-0 second-color inter-font-400"
                          data-aos="fade-right"
                          data-aos-offset="300"
                          data-aos-easing="ease-in-sine"
                        >
                          With overall experience of 8 years working in the
                          industry, I have had the opportunity to work in
                          different projects and different stacks. I have
                          experience in working with different programming
                          languages and frameworks. I have worked in different
                          projects like Ore, NepalTeaLLC, Klokhet, and many
                          more. I have also worked with different clients and
                          have received positive feedback from them. I have also
                          worked in different companies and have gained
                          experience in working with different teams. Due to
                          working with the clients from 50+ countries, I have
                          gained experience in getting the requirements from the
                          clients and delivering the projects on time.
                        </p>
                      </div>
                    </div>
                    {/* <!--About personal info --> */}
                    <PersonalInfo />

                    {/* <!--About programming and language --> */}
                    <Programming />

                    {/* <!--About Service section here --> */}
                    <ServiceSection />

                    {/* <!--About eduction and experienced --> */}
                    <EduAndExp />

                    {/* <!--About interest section --> */}
                    <Interest />

                    {/* <!--About award section --> */}
                    <Awards />

                    {/* <!--About client section --> */}
                    {/* <ClientSection /> */}

                    {/* <!--About Testimonial section --> */}
                    <TestimonialSection />
                  </div>
                  {/* <!--weekend-mode start here--> */}
                  <WeekendModeContent />
                  {/* <!--social icon start here --> */}
                  <SocialIcon />
                  {/* <!--footer content start here --> */}
                  <Footer />
                </div>
              </div>
              {/* <!--right side ipatco content end here --> */}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default About;
