import React from "react";
import BestDeveloperImg from "../../assets/images/awrds/developer.png";
import BestDesignerImg from "../../assets/images/awrds/website.png";
import DesignerImg from "../../assets/images/awrds/designer.png";

const Awards = () => {
  return (
    <>
      <div className="salv-about-award">
        <h6 className="salv-about-personla-title mb-0 b-text sm-md-font inter-font-700">
          awrads
        </h6>
        <div className="row salv-inter-award-list">
          <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xxl-4 award-col award-one">
            <div className="salv-award-card">
              <div className="salv-main-card-wrap">
                <div className="salv-card-display">
                  <div className="awrd-year b-text sm-font">2014</div>
                  <img src={BestDeveloperImg} alt="this is a developer icon" />
                </div>
                <div className="salv-awrd-card-hover">
                  <h2 className="mb-0 mt-0 b-text sm-md-font inter-font-500 salv-award-name">
                    Best Application Software
                  </h2>
                  <p className="mb-0 sm-font-two second-color inter-font-400 salv-awrd-desc">
                    Developed an complex application using C programming
                    language which was awarded the best application software in
                    the college.
                  </p>
                </div>
              </div>
              <div className="card--border"></div>
            </div>
          </div>
          <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xxl-4 award-col award-two">
            <div className="salv-award-card">
              <div className="salv-main-card-wrap">
                <div className="salv-card-display">
                  <div className="awrd-year b-text sm-font">2019</div>
                  <img src={BestDesignerImg} alt="this is a designer icon" />
                </div>
                <div className="salv-awrd-card-hover">
                  <h2 className="mb-0 mt-0 b-text sm-md-font inter-font-500 salv-award-name">
                    Employee of the Month
                  </h2>
                  <p className="mb-0 sm-font-two second-color inter-font-400 salv-awrd-desc">
                    Pearl Organization awarded me as the employee of the month
                    for the month of march 2019.
                  </p>
                </div>
              </div>
              <div className="card--border"></div>
            </div>
          </div>
          <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xxl-4 award-col award-three">
            <div className="salv-award-card">
              <div className="salv-main-card-wrap">
                <div className="salv-card-display">
                  <div className="awrd-year b-text sm-font">2020</div>
                  <img src={DesignerImg} alt="this is a website icon" />
                </div>
                <div className="salv-awrd-card-hover">
                  <h2 className="mb-0 mt-0 b-text sm-md-font inter-font-500 salv-award-name">
                    Employee of the Year
                  </h2>
                  <p className="mb-0 sm-font-two second-color inter-font-400 salv-awrd-desc">
                    Pearl Organization awarded me as the employee of the year
                    for the year 2020 and provided me with a certificate of
                    appreciation.
                  </p>
                </div>
              </div>
              <div className="card--border"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Awards;
