import React, { useState } from "react";
import Footer from "./Footer";
import WeekendModeContent from "./common/WeekendModeContent";
import SocialIcon from "./common/SocialIcon";
import MsgForm from "./contact/MsgForm";
import GetTouch from "./contact/GetTouch";
import MrThitoLogo from "../assets/images/logo/logo.png";
import ProfileImg from "../assets/images/homepage/profile-image.png";
import { Link } from "react-router-dom";
import ParticleAnimation from "./ParticleAnimation";
import Title from "./common/Title";
import Header from "./Header";

const Contact = () => {
  const [isBurgerClicked, setIsBurgerClicked] = useState(false);

  const handleBurgerClick = () => {
    setIsBurgerClicked((prevClicked) => !prevClicked);
  };

  Title("Contact | Prashant Rijal");
  return (
    <>
      <div className="ipatco-page-wrap">
        <section className="ipatco-fixed-content" id="contact_us">
          <div className="container-fluid">
            <div className="row home-content-row">
              {/* <!--Left side ipatco fixed image start here--> */}
              <ParticleAnimation />
              {/* <!--Left side ipatco fixed image end here --> */}
              {/* <!--right side ipatco content start here--> */}
              <div className="col-12 col-md-12 col-lg-8 col-xxl-7 ipatco-home-content p-0">
                <div
                  className={`ipatco-about-wrap ${
                    isBurgerClicked ? "border-none" : ""
                  }`}
                >
                  {/* <!--header content start here --> */}
                  <Header active="contact" />

                  {/* <!--contact us content start here --> */}
                  <div className="ipatco-contact-us-content weekend-mode-hide">
                    <div className="salv-inrto-content">
                      <h2
                        className="salv-about-title salv-home-title md-font b-text inter-font-900 mb-0"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"
                      >
                        Contact
                      </h2>
                      <p
                        className="salv-about-desc mb-0 second-color inter-font-400"
                        data-aos="fade-right"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                      >
                        Hey there! I am always open to new projects,
                        collaborations, or if you just want to say hi, feel free
                        to drop me a message. I will get back to you as soon as
                        possible.
                      </p>
                    </div>

                    {/* <!--get in touch content start here --> */}
                    <GetTouch />

                    {/* <!--message me content start here --> */}
                    <MsgForm />
                  </div>
                  {/* <!--weekend-mode start here--> */}
                  <WeekendModeContent />

                  {/* <!--social icon start here --> */}
                  <SocialIcon />

                  {/* <!--footer content start here --> */}
                  <Footer />
                </div>
                {/* <!--right side ipatco content end here --> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default Contact;
