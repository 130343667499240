import React, { useState } from "react";
import SocialIcon from "./common/SocialIcon";
import Footer from "./Footer";
import ImgOne from "../assets/images/blogs/blogs-one.png";
import ImgTwo from "../assets/images/blogs/blogs-two.png";
import ImgThree from "../assets/images/blogs/blogs-three.png";
import ImgFour from "../assets/images/blogs/blogs-four.png";
import ImgFive from "../assets/images/blogs/blogs-five.png";
import ImgSix from "../assets/images/blogs/blogs-six.png";
import ImgSeven from "../assets/images/blogs/blogs-seven.png";
import ImgEight from "../assets/images/blogs/blogs-eight.png";
import ImgNine from "../assets/images/blogs/blogs-nine.png";
import ImgTen from "../assets/images/blogs/blogs-ten.png";
import MrThitoLogo from "../assets/images/logo/logo.png";
import ProfileImg from "../assets/images/homepage/profile-image.png";
import WeekendModeContent from "./common/WeekendModeContent";
import SingleBlogsContent from "./blogs/SingleBlogsContent";
import { Link } from "react-router-dom";
import ParticleAnimation from "./ParticleAnimation";
import Title from "./common/Title";
import Header from "./Header";

const Blogs = () => {
  const [visibleBlogs, setVisibleBlogs] = useState(3); // State to manage number of visible blogs
  const [isBurgerClicked, setIsBurgerClicked] = useState(false);

  const handleBurgerClick = () => {
    setIsBurgerClicked((prevClicked) => !prevClicked);
  };
  // Event handler for loading more blogs
  const loadMoreBlogs = () => {
    setVisibleBlogs((prevVisibleBlogs) => prevVisibleBlogs + 3);
  };

  const [showContent, setShowContent] = useState(false);

  const handleReadMoreClick = () => {
    setShowContent(true);
  };

  const handleBackClick = () => {
    setShowContent(false);
  };
  const blogs = [
    {
      id: 1,
      title: "What You Know & Don't Know About Design.",
      description:
        "Ipsum, mauris enim, vulputate hendrerit purus facilisis nulla vulputate. Dignissim egestas vulputate tortor enim montes, a. Vel varius elit id nunc turpis laoreet facilisis. Varius rutrum nisl cursus imperdiet sodales fringilla amet, non urna.",
      image: ImgOne,
    },
    {
      id: 2,
      title: "Why every photographer should shoot film, even in 2022.",
      description:
        "Ipsum, mauris enim, vulputate hendrerit purus facilisis nulla vulputate. Dignissim egestas vulputate tortor enim montes, a. Vel varius elit id nunc turpis laoreet facilisis. Varius rutrum nisl cursus imperdiet sodales fringilla amet, non urna.",
      image: ImgTwo,
    },
    {
      id: 3,
      title: "30 Beautiful Google Fonts For Your Website.",
      description:
        "Ipsum, mauris enim, vulputate hendrerit purus facilisis nulla vulputate. Dignissim egestas vulputate tortor enim montes, a. Vel varius elit id nunc turpis laoreet facilisis. Varius rutrum nisl cursus imperdiet sodales fringilla amet, non urna.",
      image: ImgThree,
    },
    {
      id: 4,
      title: "Women In Web Design: How To Achieve Success.",
      description:
        "Ipsum, mauris enim, vulputate hendrerit purus facilisis nulla vulputate. Dignissim egestas vulputate tortor enim montes, a. Vel varius elit id nunc turpis laoreet facilisis. Varius rutrum nisl cursus imperdiet sodales fringilla amet, non urna.",
      image: ImgFour,
    },
    {
      id: 5,
      title: "Format releases a new tool that enables direct video hosting.",
      description:
        "Ipsum, mauris enim, vulputate hendrerit purus facilisis nulla vulputate. Dignissim egestas vulputate tortor enim montes, a. Vel varius elit id nunc turpis laoreet facilisis. Varius rutrum nisl cursus imperdiet sodales fringilla amet, non urna.",
      image: ImgFive,
    },
    {
      id: 6,
      title: "Efficiency Tools Every Logo Designer Needs.",
      description:
        "Ipsum, mauris enim, vulputate hendrerit purus facilisis nulla vulputate. Dignissim egestas vulputate tortor enim montes, a. Vel varius elit id nunc turpis laoreet facilisis. Varius rutrum nisl cursus imperdiet sodales fringilla amet, non urna.",
      image: ImgSix,
    },
    {
      id: 7,
      title: "Stay creative in lockdown with these fun photo projects",
      description:
        "Ipsum, mauris enim, vulputate hendrerit purus facilisis nulla vulputate. Dignissim egestas vulputate tortor enim montes, a. Vel varius elit id nunc turpis laoreet facilisis. Varius rutrum nisl cursus imperdiet sodales fringilla amet, non urna.",
      image: ImgSeven,
    },
    {
      id: 8,
      title: "Curating a workplace that inspires all of us.",
      description:
        "Ipsum, mauris enim, vulputate hendrerit purus facilisis nulla vulputate. Dignissim egestas vulputate tortor enim montes, a. Vel varius elit id nunc turpis laoreet facilisis. Varius rutrum nisl cursus imperdiet sodales fringilla amet, non urna.",
      image: ImgEight,
    },
    {
      id: 9,
      title: "30 Beautiful Google Fonts For Your Website.",
      description:
        "Ipsum, mauris enim, vulputate hendrerit purus facilisis nulla vulputate. Dignissim egestas vulputate tortor enim montes, a. Vel varius elit id nunc turpis laoreet facilisis. Varius rutrum nisl cursus imperdiet sodales fringilla amet, non urna.",
      image: ImgNine,
    },
    {
      id: 10,
      title: "Evolving reusability and maintainability in digital market.",
      description:
        "Ipsum, mauris enim, vulputate hendrerit purus facilisis nulla vulputate. Dignissim egestas vulputate tortor enim montes, a. Vel varius elit id nunc turpis laoreet facilisis. Varius rutrum nisl cursus imperdiet sodales fringilla amet, non urna.",
      image: ImgTen,
    },
  ];
  Title("Blogs | Prashant Rijal");
  return (
    <>
      <section className="ipatco-fixed-content" id="blogs_page">
        <div className="container-fluid">
          <div className="row home-content-row">
            {/* <!--Left side ipatco fixed image start here--> */}
            <ParticleAnimation />
            {/* <!--Left side ipatco fixed image end here --> */}
            {/* <!--right side ipatco content start here--> */}
            <div className="col-12 col-md-12 col-lg-8 col-xxl-7 ipatco-home-content p-0">
              <div
                className={`ipatco-about-wrap ${
                  isBurgerClicked ? "border-none" : ""
                }`}
              >
                {/* <!--header content start here --> */}
                <Header active="blogs" />

                {/* <!--blogs content start here --> */}
                <div className="ipatco-blogs-main-wrap">
                  <div
                    className={`ipatco-blogs-content weekend-mode-hide ${
                      showContent ? "hidepage" : ""
                    }`}
                  >
                    <div className="salv-inrto-content portfolio-title">
                      <h2
                        className="salv-about-title salv-home-title md-font b-text inter-font-900 mb-0"
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"
                      >
                        Blogs
                      </h2>
                    </div>

                    {/* <!--blog card content start here --> */}
                    <div className="slv-blog-list">
                      {blogs.slice(0, visibleBlogs).map((blog, index) => (
                        <div
                          key={blog.id}
                          className={`salv-blog-card ${
                            blog.id % 2 === 0 ? "reverse" : ""
                          }`}
                        >
                          <div className="salv-blog-wrap">
                            <div className="salv-blogs-meta">
                              <div
                                className="salv-blogs-photo"
                                style={{
                                  backgroundImage: `url(${blog.image})`,
                                }}
                              ></div>
                              <div className="salv-blogs-details"></div>
                            </div>
                            <div
                              className={`salv-blogs-description ${
                                blog.id % 2 === 0
                                  ? "salv-blogs-description-reverse"
                                  : ""
                              }`}
                            >
                              <h3 className="salv-blogs-title mb-0 inter-font-700">
                                {blog.title}
                              </h3>
                              <p className="salv-blogs-desc mb-0 inter-font-400 sm-md-font-two">
                                {blog.description}
                              </p>
                              <p className="salv-read-more mb-0">
                                <a onClick={handleReadMoreClick}>Read More</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}

                      <div className="salv-load-blogs">
                        {blogs.length > visibleBlogs && (
                          <a
                            onClick={loadMoreBlogs}
                            className="sal-load-more"
                            style={{ color: "black" }}
                          >
                            Load More
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <!--single blogs blog page content start here --> */}
                  {showContent && (
                    <div
                      className={`ipatco-single-blogs-content weekend-mode-hide ${
                        showContent ? "showpage" : ""
                      }`}
                    >
                      <SingleBlogsContent />
                      <div className="blog-back-btn">
                        <button className="blog-btn" onClick={handleBackClick}>
                          Back To Post
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                {/* <!--weekend-mode start here--> */}
                <WeekendModeContent />
                {/* <!--social icon start here --> */}
                <SocialIcon />
                {/* <!--footer content start here --> */}
                <Footer />
              </div>
            </div>
            {/* <!--right side ipatco content end here --> */}
          </div>
        </div>
      </section>
    </>
  );
};
export default Blogs;
