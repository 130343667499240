import React from "react";

const EduAndExp = () =>
{
    return (
      <>
        <div className="salv-about-edu-expr">
          <div className="row sal-about-eduction-row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 salv-eduction-col">
              <h6 className="salv-about-personla-title mb-0 b-text sm-md-font inter-font-700 mb-0">
                eduction
              </h6>
              <div className="about-educ-card-wrap">
                <div className="eduction-card edu-card-one">
                  <div className="year-edu-className">
                    <div className="educ-year">2019 - 2021</div>
                    <p className="about-edu-title sm-md-font b-text inter-font-500 mb-0">
                      MSc. Information Technology
                    </p>
                  </div>
                  <div className="about-desc sm-font-two inter-font-400 second-color">
                    Uttaranchal College of Science and Technology, Dehradun
                    affiliated to HNB Garhwal University
                  </div>
                </div>
                <div className="eduction-card edu-card-two">
                  <div className="year-edu-className">
                    <div className="educ-year">2014 - 2017</div>
                    <p className="about-edu-title sm-md-font b-text inter-font-500 mb-0">
                      Bachelor of Computer Application
                    </p>
                  </div>
                  <div className="about-desc sm-font-two inter-font-400 second-color">
                    Baba Farid Institute of Technology, Dehradun affiliated to
                    HNB Garhwal University
                  </div>
                </div>
                <div className="eduction-card edu-card-two">
                  <div className="year-edu-className">
                    <div className="educ-year">2012 - 2014</div>
                    <p className="about-edu-title sm-md-font b-text inter-font-500 mb-0">
                      12<sup>th</sup> (Commerce)
                    </p>
                  </div>
                  <div className="about-desc sm-font-two inter-font-400 second-color">
                    Saptakoshi Higher Secondary School, Urlabari, Koshi, Nepal
                    affiliated to HSEB Board
                  </div>
                </div>
                <div className="eduction-card edu-card-three">
                  <div className="year-edu-className">
                    <div className="educ-year">2011</div>
                    <p className="about-edu-title sm-md-font b-text inter-font-500 mb-0">
                      10<sup>th</sup> (SLC)
                    </p>
                  </div>
                  <div className="about-desc sm-font-two inter-font-400 second-color">
                    Modern Era English Boarding School, Biratnagar, Koshi, Nepal affiliated to
                    SLC Board
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 salv-experience-col">
              <h6 className="salv-about-personla-title mb-0 b-text sm-md-font inter-font-700 mb-0">
                experience
              </h6>
              <div className="about-educ-card-wrap">
                <div className="eduction-card edu-card-one">
                  <div className="year-edu-className">
                    <div className="educ-year">2023 - Running</div>
                    <p className="about-edu-title sm-md-font b-text inter-font-500 mb-0">
                      iPatco Tech.
                    </p>
                  </div>
                  <div className="about-desc sm-font-two inter-font-400 second-color">
                    Bootstrapped startup with a team of 9 members working on
                    different fields like WIX, PHP, JS, React, Shopify, etc.
                  </div>
                </div>
                <div className="eduction-card edu-card-two">
                  <div className="year-edu-className">
                    <div className="educ-year">2020 - 2023</div>
                    <p className="about-edu-title sm-md-font b-text inter-font-500 mb-0">
                      Evon Technologies Pvt. Ltd.
                    </p>
                  </div>
                  <div className="about-desc sm-font-two inter-font-400 second-color">
                    Worked as a Web Developer and developed various projects
                    using Laravel, Python, React, etc.
                  </div>
                </div>
                <div className="eduction-card edu-card-two">
                  <div className="year-edu-className">
                    <div className="educ-year">2019 - 2020</div>
                    <p className="about-edu-title sm-md-font b-text inter-font-500 mb-0">
                      Pearl Organisation
                    </p>
                  </div>
                  <div className="about-desc sm-font-two inter-font-400 second-color">
                    Worked as a Web Developer and developed various projects
                    using PHP, JS, WordPress, etc.
                  </div>
                </div>
                <div className="eduction-card edu-card-four">
                  <div className="year-edu-className">
                    <div className="educ-year">2018 - 2019</div>
                    <p className="about-edu-title sm-md-font b-text inter-font-500 mb-0">
                      Binastre Technologies
                    </p>
                  </div>
                  <div className="about-desc sm-font-two inter-font-400 second-color">
                    Worked as a Web Developer and developed various projects
                    using PHP, JS, WordPress, etc.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};
export default EduAndExp;