import React from "react";
import { Link } from "react-router-dom";

const PersonalInfo = () => {
  return (
    <>
      <div className="salv-about-personal">
        <h3 className="salv-about-personla-title mb-0 b-text sm-md-font inter-font-700">
          Personal Informations
        </h3>
        <div className="row personal-info-row">
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 personal-first-col">
            <div className="pers-info-wrap">
              <span className="pers-info-title b-text sm-md-font inter-font-400">
                Name:
              </span>
              <span className="pers-info-title second-color sm-md-font inter-font-400 m-1">
                Prashant Rijal
              </span>
            </div>
            <div className="pers-info-wrap">
              <span className="pers-info-title b-text sm-md-font inter-font-400">
                Language:
              </span>
              <span className="pers-info-title second-color sm-md-font inter-font-400 m-1">
                Nepali, Hindi, English
              </span>
            </div>
            <div className="pers-info-wrap">
              <span className="pers-info-title b-text sm-md-font inter-font-400">
                Address:
              </span>
              <span className="pers-info-title second-color sm-md-font inter-font-400 m-1">
                9, Ratuwamai, Itahara, Koshi, Nepal / Dehradun, UK, India
              </span>
            </div>
            <div className="pers-info-wrap">
              <span className="pers-info-title b-text sm-md-font inter-font-400">
                Age:
              </span>
              <span className="pers-info-title second-color sm-md-font inter-font-400 m-1">
                {new Date().getFullYear() - 1996} Years
              </span>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 personal-second-col">
            <div className="pers-info-wrap">
              <span className="pers-info-title b-text sm-md-font inter-font-400">
                University:
              </span>
              <span className="pers-info-title second-color sm-md-font inter-font-400 m-1">
                Hemwati Nandan Bahuguna Garhwal University
              </span>
            </div>
            <div className="pers-info-wrap">
              <span className="pers-info-title b-text sm-md-font inter-font-400">
                Degree:
              </span>
              <span className="pers-info-title second-color sm-md-font inter-font-400 m-1">
                {" "}
                BCA, MSc. IT
              </span>
            </div>
            <div className="pers-info-wrap">
              <span className="pers-info-title b-text sm-md-font inter-font-400">
                E-Mail:
              </span>
              <span className="pers-info-title">
                <Link
                  to="mailto:prashant@rijal.me"
                  className="second-color sm-md-font inter-font-400 links m-1"
                >
                  prashant@rijal.me
                </Link>
              </span>
            </div>
            <div className="pers-info-wrap">
              <span className="pers-info-title b-text sm-md-font inter-font-400">
                Phone:
              </span>
              <span className="pers-info-title ">
                <Link
                  to="tel:+202345678899"
                  className="second-color sm-md-font inter-font-400 links m-1"
                >
                  +91 7454947738
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PersonalInfo;
